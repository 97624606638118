import { Rule } from "@with-nx/simple-ui/atoms";
import { DesignedButton } from "@with-nx/simple-ui/atoms";
import { useRouter } from "next/router";
import { FC } from "react";
import { Box } from "simple-effing-primitive-layout";

import ReusableModalComponent from "../ReusableModalComponent";

interface RedirectCartModalProps {
  title?: string;
  message?: string;
  modal?: boolean;
  _modal?: (modal: boolean) => void;
}

export const RedirectCartModal: FC<RedirectCartModalProps> = ({
  title,
  modal,
  _modal,
  message,
}) => {
  const router = useRouter();

  return (
    <ReusableModalComponent
      title="Checkout"
      isOpen={modal}
      onClose={() => _modal(false)}
      isCentered
    >
      <Box>
        <Rule parse="d:block mb:32">
          {message
            ? message
            : `${title} has been successfully added to your cart`}
        </Rule>

        <Box parse="d:flex mb:16">
          <Box parse="mr:12">
            <DesignedButton
              icon="cart/bold"
              label="Continue shopping"
              press={() => _modal(false)}
            />
          </Box>

          <DesignedButton
            theme="tetriary"
            icon="shopping_bag"
            label="Go to Checkout"
            press={() => router.push("/checkout")}
          />
        </Box>
      </Box>
    </ReusableModalComponent>
  );
};
