import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title?: React.ReactNode;
  children: React.ReactNode;
  darkMode?: boolean;
  style?: React.CSSProperties;
  size?: string;
  isCentered?: boolean;
}

const ReusableModalComponent: React.FC<Props> = ({
  isOpen,
  onClose,
  title,
  children,
  style,
  size = "lg",
  isCentered = false,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      blockScrollOnMount={false}
      onClose={onClose}
      size={{ base: "sm", md: size }}
      isCentered={isCentered}
    >
      <ModalOverlay />

      <ModalContent borderRadius="md" backgroundColor="var(--foreground)">
        <div style={style}>
          <ModalHeader color="var(--font1)" style={{ minHeight: 48 }}>
            {title}
          </ModalHeader>
          <Box position="absolute" right={0} top={0}>
            <Flex
              justifyContent="flex-end"
              alignItems="center"
              position="relative"
              width="100%"
            >
              <ModalCloseButton color="var(--font1)" />
            </Flex>
          </Box>
          <div style={{ borderBottom: "1px solid #2D3542" }} />
          <ModalBody>{children}</ModalBody>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ReusableModalComponent;
